import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import authService from "@utils/services/AuthService";
// import { getZendeskScriptInfo, logoutZendesk } from "@hooks/useZendeskService";
import { useConfigurationStore, useUIStore, useUserPreferencesStore } from "@stores";

// import { URLS } from "@constants/urls";
// import { userRoles } from "@constants/index";
// import localStorageKeys from "@constants/localStorageKeys";
import logTraceIdService from "@utils/services/LogTraceIdService";
// import { isUserLoggedInViaSSO } from "@utils/helpers/tokens";

type UseLogout = {
  handleLogOut: () => void;
};

const useLogout = (): UseLogout => {
  const {
    setUserProfile,
    // domainSettings,
    setSkillsSettings,
  } = useConfigurationStore();
  const { hideMainDrawer, setLiveChatVisible } = useUIStore((state) => state);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const useUserStore = useUserPreferencesStore();
  const { resetSettings } = useUserStore() ?? {};

  const handleLogOut = (): void => {
    // const isSSOLogin = isUserLoggedInViaSSO();

    // Generate new trace identifier context ID.
    logTraceIdService.generateContextId();
    // const { integration_type = null } = domainSettings?.sso ?? {};
    // const isSSOenabled = ["saml", "oidc"].some((type) => type === integration_type);

    // const userRole = authService.getDefaultRole();
    // const isAdmin = userRole === userRoles.ADMINISTRATOR;
    // const { zendeskScriptElement, zendeskUniqueId } = getZendeskScriptInfo();

    hideMainDrawer();
    document.body.style.setProperty("overflow", "");
    queryClient.clear();
    authService.removeTokens();
    authService.removeRole();
    resetSettings();
    setUserProfile(null); // On logout reset the users profile data
    setSkillsSettings(null);
    setLiveChatVisible(false); // Hide live chat on logout
    // zendeskScriptElement && zendeskUniqueId === "help-script" && isAdmin && logoutZendesk(); // Logout from zendesk
    // zendeskScriptElement && window.location.reload(); // Reload page if zendesk script exists

    // const ssoLogoutUrl = localStorage.getItem(localStorageKeys.SSO_LOGOUT_URL);
    const test = "https://skyagain.bleeding2.talentlms.com/index/ssologin/service:saml";
    navigate(test);
    // If user used sso to login
    // if (ssoLogoutUrl && isSSOLogin && isSSOenabled) {
    //   window.location.replace(ssoLogoutUrl);
    // } else {
    //   navigate(URLS.root);
    // }
  };

  return { handleLogOut };
};

export default useLogout;
